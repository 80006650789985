.header-privacy {
  height: 10em;
  width: 100vw;   
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4vw;
  box-sizing: border-box;
  position: relative;

  .main-logo {
      width: auto;
      height: 40%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
  }  

  .back-arrow {
    width: auto;
    height: 20%;    
    cursor: pointer;

    svg {
      width: auto;
      height: 100%;      
      color: #000; 
    }
  }
}

.terms {
  background-color: #d4d4d0;
  min-width: 100vw;  
  box-sizing: border-box;
  
  padding: 10vw 20vw;
  padding-top: 0;
  color: #000;
  text-align: initial;
}