#section-5 {
    height: 100vh;
    width: 100vw;   
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    background-color: #1e1e1e;
    
    // #intro-video {
    //     height: 56.25vw;
    //     width: 100vw;        
    // }

    #crash-video, .crash-screen {
        height: 100vh;
        width: 178vh;        
        min-width: 100vw;
        object-fit: cover;
        position: absolute;
        top: 0;
    }    

    .text-container {
        z-index: 5;
        height: 100%;
        width: 35%;
        margin-right: 10%;
        margin-left: auto;

        #text-6 {
            position: relative;
            top: 100%;
            opacity: 0;

            color: #FFF;
            width: 60%;
            margin-right: auto;

           .top-bar {
                width: 70%;
                height: 3px;
                margin-right: auto;
                background-color: #FFF;
           }

           .title {
                font-size: 8em;
                text-align: left;
                line-height: 1em;                
                margin: 0;
                font-weight: bold;
                letter-spacing: -5px;

                span {
                    font-size: 0.4em;
                    letter-spacing: 0;
                   // transform: translateY(-20%);
                    display: inline-block;
                }
           }

           .text {
                font-size: 1.1em;
                text-align: left;
                line-height: 1em;                
                margin: 0.5em 0;
           }
        }         

    }    
}


@media screen and (max-width: 83em) {
    #section-5 {
         .text-container {
              width: 40%;
    
              #text-6 {
                   width: 70%;
    
                   .title {
                        font-size: 6em;
                   }
         
                   .text {
                        font-size: 1em;
                   }
              
              }                
         }     
    }
}

@media screen and (max-width: 58em) {
    #section-5 {
         .text-container {
              width: 90%;
              margin-right: 5%;

              #text-6 {
                   width: 60%;
                   
                   .top-bar {
                        width: 55%;
                   }

              }                
         }     
    }
}

//phone
@media screen and (max-width: 28em) {
    #section-5 {
         .text-container {

              #text-6 {
                   width: 70%;
    
                   .title {
                        font-size: 6em;
                   }
         
                   .text {
                        font-size: 1em;
                   }
              
              }                
         }     
    }
}