header {
    height: 25vh;
    width: 100vw;   
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4vw;
    box-sizing: border-box;
    position: fixed;
    z-index: 10;
    
    .main-logo {
        width: auto;
        height: 40%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    #configurator-button, #technology-button {
        border: none;
        background: none;
        font-weight: 500;
        font-size: 1em;
        padding-bottom: 0.5em;
        font-family: 'Inter', sans-serif;
        height: fit-content;

        &.active {            
            border-bottom: 2px solid;
        }
    }

    #technology-button {
        margin-left: 1em;
        margin-right: auto;

    }

    #instagram-button {
        font-size: 1.8em;
        margin-right: 1em;
        padding-bottom: 0.25em;        

        svg {
            color: #000;
        }
    }

    #waitlist-button {
        margin-left: auto;
        border: none;
        font-size: 1em;
        color: #FFF;
        background-color: #000;
        padding: 0.4em 0.6em;
        font-family: 'Inter', sans-serif;
        height: fit-content;
    }    

    #compatibility-button {
        margin-left: 0.5em;
        border: none;
        font-size: 1em;
        color: #000;
        border: 1px solid #000;
        background-color: transparent;
        padding: 0.4em 0.6em;
        font-family: 'Inter', sans-serif;
        height: fit-content;
    }
}

@media screen and (max-width: 60em) {
    #compatibility-button {
        display: none;
    }
}

//tablet (portrait)
@media screen and (max-width: 55em) {

    header {
        height: 10vh;
        width: 100vw;   
        padding: 0 2vw;
        background-color: #f2f2f2;

        #configurator-button, #technology-button {
            display: none;
        }

        #instagram-button {
            padding-bottom: 0;
        }        

        .main-logo {
            height: 65%;
        }        

        #waitlist-button {
            font-size: 0.9em;
        }
    }    
}

//phone
@media screen and (max-width: 32em) {
    header {
        #waitlist-button {
            display: none;
        }

        #instagram-button {
            margin-right: auto;
            margin-left: 0.5em;            
        }
    }    
}