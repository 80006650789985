.loader-container {
    height: 100vh;
    width: 100vw;   
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0; left: 0;
    z-index: 10;
    
    .loader {
        height: auto;
        width: 20%;
        animation: bounce 0.75s infinite;            
        animation-direction: alternate;
        animation-timing-function: ease-in-out;            
    }
}

@keyframes bounce {
    0% { transform: rotate(-20deg); }
   100% { transform: rotate(20deg); }
}

//tablet (portrait)
@media screen and (max-width: 55em) {
    .loader-container {
        .loader {
            width: 35%;    
        }
    }
}

//phone
@media screen and (max-width: 28em) {
    .loader-container {
        .loader {
            width: 40%;    
        }
    }
}