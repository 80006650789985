#section-6 {
    height: 100vh;
    width: 100vw;   
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;    
     background-color: #1e1e1e;
     
    .left-container {
          height: 100%;
          width: 40%;
          margin-left: 5%;          

          #text-4 {
               position: relative;
               transform: translateY(-50%);
               top: -100%;
               opacity: 0;

               color: #FFF;
               width: 95%;               
               margin-left: auto;
               margin-right: 10%;
               margin-bottom: 10vh;

               .top-bar {
                    width: 60%;
                    height: 3px;
                    margin-left: auto;
                    background-color: #FFF;
               }

               .title {
                    font-size: 3.5em;
                    font-weight: bold;                    
                    text-align: right;
                    line-height: 1em;                
                    margin: 0.2em 0;
               }

               .text {
                    font-size: 1.3em;
                    text-align: right;
                    line-height: 1.1em;                
                    width: 70%;
                    margin: 0;
                    margin-left: auto;
               }
          }              
    }

    .right-container {
          height: 100%;
          width: 50%;
          margin-right: 10%;

          #weave {
               width: 100%;
               height: auto;
               transform: translateY(-50%);
               position: relative;
               top: 100%;
               opacity: 0;  
               display: block;
            //  max-width: 700px;        
          }           
    }
}

//laptop
@media screen and (max-width: 80em) {

}

//tablet (portrait)
@media screen and (max-width: 55em) {
     #section-6 {
          .left-container {
               width: 60%;
               position: absolute;

               #text-4 {
                    margin-left: 10%;
                    transform: translate(-25%, -10%);
                    

                    .top-bar {
                         width: 85%;
                         margin-left: 0;
                    }
     
                    .title {
                         font-size: 3em;
                         text-align: left;                         
                    }
     
                    .text {
                         font-size: 1em;
                         text-align: left;
                         margin-left: 0;
                         width: 90%;
                    }                    
               }               
          }

          .right-container {
               width: 100%;
               margin-left: 0;
               margin-right: 0;
               padding-right: 25%;
               z-index: 1;    
              // background-color: rgba(212, 212, 208, 0.6);     

               #weave {
                    transform: translate(25%, -10%);
               }
          }
     }
}

//phone
@media screen and (max-width: 28em) {
     #section-6 {
          .left-container {
               width: 70%;
               position: absolute;
               margin-left: 0;

               #text-4 {
                    width: 90%;
                    margin-bottom: 10%;
                    margin-left: 10%;

                    .top-bar {
                         width: 85%;
                    }
     
                    .title {
                         font-size: 2.5em;
                    }
                  
               }               
          }
     }
}