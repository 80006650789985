#section-7 {
    height: 100vh;
    width: 100vw;   
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    background: url('../images/bg-last.jpg');
    background-size: cover;
    background-position: top right;
    
     .left-container {
          height: 100%;
          width: 55%;
          margin-left: 8vw;
          margin-right: auto;
          padding-top: 300px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          
          form {
               display: flex;
               flex-wrap: wrap;
               flex-direction: column;
               margin: 0;
               margin-top: 2em;
          }

          .form-input {
               display: flex;
               flex-wrap: nowrap;
               flex-direction: row;
          }

          .error-message, .confirm-message {
               text-align: right;
               color: #DD0000;
          }

          .confirm-message {
               color: #e93be7;
          }

          input {
               background-color: #FFF;
               font-size: 1.2em;
               padding: 1em;
               padding-top: 1.2em;
               width: 50%;
               color: #000;
               font-family: 'Inter', sans-serif; 
               border: none; 
               margin: 0;
               margin-left: auto;
               line-height: 1em;
               outline: none;
           }
       
           button {
               background-color: none;
               font-size: 1.2em;
               padding: 1em;
               padding-top: 1.1em;
               color: #FFF;   
               border: 1px solid #FFF;  
               cursor: pointer;
               font-family: 'Inter', sans-serif; 
               
               &:hover {
                   color: #000;
                   background-color: #FFF;
                   border: 1px solid #FFF; 
               }
       
               &:disabled {
                   opacity: 0.3;
                   cursor: default;
       
                   &:hover {
                       color: #FFF;
                       background-color: none;
                       border: 1px solid #FFF; 
                   }            
               }
           }   
           
           .disclaimer-bottom {
               font-size: 0.7em;
               font-weight: 600;
               text-align: right;
               margin: 0;
               margin-top: 0.7em;
               color: #FFF;
           }
     }     

     #text-5 {
          color: #FFF;
          width: 85%;          
          margin-left: auto;
          margin-right: 10%;
          margin-bottom: 20vh;

          .top-bar {
               width: 80%;
               height: 3px;
               margin-left: auto;
               background-color: #FFF;
          }

          .title {
               font-size: 3.2em;
               font-weight: bold;               
               text-align: right;
               line-height: 1em;                
               margin: 0.2em 0;
          }

          .text {
               font-size: 1.2em;
               text-align: right;
               line-height: 1em;                
               width: 100%;
               margin: 0;
               margin-left: auto;
          }
     }     
     
     .right-container {
          height: 100%;
          width: 70%;
          position: relative;
     }

     .car {
          width: 100%;
          height: auto;
          position: absolute;
          bottom: 0;
          right: 0;
     }
}

@media screen and (max-width: 69em) {

     #section-7 {
          .left-container {
               width: 60%;

               input, button {
                    font-size: 1.1em;
               }

               #text-5 {
                    
                    .top-bar {
                         width: 80%;
                    }
          
                    .title {
                         font-size: 3em;
                    }
          
                    .text {
                         font-size: 1.2em;
                    }
               }                  
          }

          .right-container {
               width: 60%;
          }     
     }
}

@media screen and (max-width: 55em) {

     #section-7 {
          flex-wrap: wrap;
          height: initial;

          .left-container {
               width: 90%;
               padding-top: 20vh;
               padding-bottom: 30vh;

               input, button {
                    font-size: 1em;
                    margin-left: 0;
               }

               .disclaimer {
                    text-align: left;
               }

               #text-5 {
                    
                    .top-bar {
                         width: 70%;
                         margin-left: 0;
                    }
          
                    .title {
                         font-size: 2.6em;
                         text-align: left;
                    }
          
                    .text {
                         font-size: 1em;
                         text-align: left;
                    }
               }                  
          }

          .right-container {
               width: 100%;
               
               .car {
                    width: 50%;
               }
          }     
     }
}

//phone
@media screen and (max-width: 28em) {
     #section-7 {

          .left-container {
               width: 100%;
               padding-left: 0.1em;
               padding-right: 0.1em;
               padding-bottom: 0vh;  
               
               #text-5 {

                    .title {
                         font-size: 2.6em;
                         text-align: left;
                    }
          
                    .text {
                         font-size: 1em;
                         text-align: left;
                    }
               }     

               input, button {
                    font-size: 0.9em;
               }
          }

          .right-container {
               display: none;
          }     
     }
}