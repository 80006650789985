@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter',
    sans-serif;
  background-color: #d4d4d0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

button {
  border: none;
  background: none;
  font-weight: 500;
  font-size: 1.2em;
  font-family: 'Inter', sans-serif;
  height: fit-content;
  cursor: pointer;
}

.down-chevron {
  position: fixed;
  bottom: 0; left: 0;
  height: 0;
  width: 0;
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: bounce-small 1s infinite;            
  animation-direction: alternate;
  animation-timing-function: ease-in-out;      
  transition-property: opacity;
  transition-duration: 500ms;
  
  &.visible {
    opacity: 1;
    height: 20vh;
    width: 100vw;    
  }

  p {
       color: #FFF;
       font-size: 1em;
       text-align: center;
       margin: 0;
  }

  svg {
       bottom: 5vh;
       margin: 0 auto;
       min-width: 16px;    
       width: 2vw;
       height: auto;
  }  
}  

@keyframes bounce-small {
  0% { transform: scale(0.9); }
 100% { transform: scale(1); }
}    