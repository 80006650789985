#section-3 {
    height: 100vh;
    width: 100vw;   
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;    

    .left-container {
          height: 100%;
          width: 40%;
          margin-left: 5%;          

          #door {
               width: 120%;
               height: auto;

               position: relative;
               top: 100%;
               display: block;
              // opacity: 0;          
          }      
      
    }

    .right-container {
          height: 100%;
          width: 50%;
          margin-right: 10%;

          #text-3 {
               position: relative;
               top: -100%;
               opacity: 0;

               color: #000;
               width: 70%;
               margin-left: auto;
               margin-bottom: 10vh;

               .top-bar {
                    width: 95%;
                    height: 3px;
                    margin-right: auto;
                    background-color: #000;
               }

               .title {
                    font-size: 3.5em;
                    font-weight: bold;                    
                    text-align: left;
                    line-height: 1em;                
                    margin: 0.2em 0;
               }

               .text {
                    font-size: 1.3em;
                    text-align: left;
                    line-height: 1em;                
                    width: 100%;
                    margin: 0;
                    margin-right: auto;
               }
          }        
 
    }
}

//laptop
@media screen and (max-width: 80em) {

}

//tablet (portrait)
@media screen and (max-width: 55em) {
     #section-3 {
          .left-container {
               width: 60%;
               position: absolute;            
          }

          .right-container {
               width: 100%;
               margin-left: 0;
               margin-right: 0;
               padding-right: 25%;
               z-index: 1;    

               #text-3 {
                    margin-left: 10%;

                    .top-bar {
                         width: 85%;
                    }
     
                    .title {
                         font-size: 3em;
                    }
     
                    .text {
                         font-size: 1em;
                    }                    
               }
          }
     }
}

//phone
@media screen and (max-width: 28em) {
     #section-3 {
          .left-container {
               width: 70%;
               position: absolute;
               margin-left: 0;             
          }

          .right-container {
               
               #text-3 {
                    width: 80%;

                    margin-left: 10%;

                    .top-bar {
                         width: 85%;
                    }
     
                    .title {
                         font-size: 2.5em;
                    }
                  
               }
          }
     }
}