footer {
    height: 5em;
    width: 100vw;   
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 4vw;
    box-sizing: border-box;
    background-color: rgba(212, 212, 208, 1);

    a {
         color: #000;
         font-weight: 500;
         font-size: 1em;
         font-family: 'Inter', sans-serif;               
         text-decoration: none;
    }
}