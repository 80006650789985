#section-2 {
    height: 115vh;
    width: 100vw;   
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;    
    position: relative;    

     .left-container {
          height: 100%;
          width: 30%;
          margin-left: 10vw;
          margin-right: auto;
          padding-top: 40vh;          
     }
     
     .sub-title {
          font-size: 1.2em;
          margin: 0;
          margin-top: 1em;
          padding-left: 0;
     }

     .title {
          font-size: 3em;
          font-weight: bold;          
          width: 100%;
          text-align: left;
          margin: 0;
          padding-left: 0;
     }

     .selection {
          opacity: 0.3;       
          transition-property: opacity;
          transition-duration: 200ms;
          color: inherit;            

          &.active {
               opacity: 1;              
          }      
          
          &:hover {
               opacity: 1;
          }                
     }
     
     .character-selection {
          &.active {
               &:after {
                    content:url('../images/info.svg');
                    width: 0.4em;
                    display: inline-block;
                    margin-left: 0.2em;
                    transform: translateY(5%);
                }                  
          }              
     }

     .performance {
          cursor: pointer;

          &:after {
               content:url('../images/plus.svg');
               width: 0.4em;
               display: inline-block;
               margin-left: 0.2em;
               transform: translateY(-5%);
           }              
     }

     .character-quote {
          height: 0;
          overflow: hidden;
          padding: 0 1em;
          margin: 0;
          font-size: 0.9em;
          width: 65%;
          // transition-property: height;
          // transition-duration: 300ms;
     }

     .text {
          width: 80%;
          margin: 0;
          height: 0;
          padding-left: 0;
          overflow: hidden;
     }

     .config-button {
          border: none;
          font-size: 1.3em;
          color: #FFF;
          background-color: #000;
          padding: 0.4em 1.2em;
          font-family: 'Inter', sans-serif;
          height: fit-content;    
          margin: 0;
          margin-top: 1em;                

          transition-duration: 500ms;
            
          &:hover {
              color: #000;
              background-color: #FFF;       
              border: 1px solid #000;         
          }          
     }

     .animation-container {   
          width: 60%;
          height: 100%;
          padding-top: 10vh;
          margin-right: 5%;
          display: flex;
          flex-direction: column;

          .animation-canvas-container {
               width: 100%;
               flex: 1;
               margin-top: auto;
               margin-bottom: 15%;   
               position: relative;              

               canvas {
                    position: absolute;
                    bottom: 0; left: 0;
                    width: 100%;
                    height: 100%;
                    transform: translate(11%, 7%);

                    &.ren, &.hiro {
                         transform: translate(10%, 10%);
                    }
                
               }            
          }

          .animation {
               width: 100%;
               height: auto;
               margin-top: auto;
               margin-bottom: 15%;
          }
     }
     
     .flower {
          height: 80vh;
          width: auto;
          position: absolute;
          left: 55%;
          top: 20%;
          transform: translateX(-50%);
     }

}

//laptop
@media screen and (max-width: 80em) {

}

//tablet (portrait)
@media screen and (max-width: 55em) {
     #section-2 {

          .left-container {
               width: 100%;
               margin-left: 0;
               padding-left: 5%; 
               padding-right: 45%;
               z-index: 1;
               padding-top: 15vh;       
               background-color: rgba(212, 212, 208, 0.6);        
          }

          .sub-title {
               font-size: 1em;
               margin-top: 0.8em;
          }
     
          .title {
               font-size: 1.8em;           
          }

          .config-button {
               font-size: 1em;  
          }

          .animation-container, .animation-canvas-container {   
               width: 80%;
               position: absolute;
               bottom: 0;
               right: 0;
     
               .animation, canvas {
                    width: 100%;
                    height: auto;
                    margin-bottom: 10%;
               }
          }     
          
          .flower {
               left: 25%;
          }          
     
     }
}

//phone
@media screen and (max-width: 28em) {
     #section-2 {

          .left-container {
               padding-right: 5%;
               padding-top: 5%;
               background-color: initial;
          }          
          
          .sub-title {
               font-size: 0.8em;
               margin-top: 0.6em;
          }
     
          .title {
               font-size: 1.5em;
          }

          .config-button {
               font-size: 0.8em;  
          }

          .character-quote {
               height: 0;
               overflow: hidden;
               padding: 0 2em;
               margin: 0;
               font-size: 0.9em;
               width: 90%;
               // transition-property: height;
               // transition-duration: 300ms;
          }

          .animation-container, .animation-canvas-container {   
               width: 85%;
               transform: translateY(8%);

               .animation, canvas {
                    margin-bottom: 30%;
               }
          }          

          .flower {
               height: auto;
               width: 30%;
               top: initial;
               bottom: 20%;
          }          
     
     }
}