#section-1 {
    height: 100vh;
    width: 100vw;   
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    // #intro-video {
    //     height: 56.25vw;
    //     width: 100vw;        
    // }

    #intro-video, .intro-screen {
        height: 100vh;
        width: 178vh;        
        min-width: 100vw;
        object-fit: cover;
        position: absolute;
        top: 0;
    }    

    .intro-screen {
          opacity: 0;
    }

    #intro-video.vertical-video {
          height: 178vw;
          width: 100vw;        
          min-width: initial;
          min-height: 100vh;
          position: absolute;
          top: initial;  
          left: 0;        
    }

    .text-container {
        z-index: 5;
        height: 100%;
        width: 35%;
        margin-right: 10%;
        margin-left: auto;

        #text-1 {
            position: relative;
            top: 100%;
            opacity: 0;

            color: #FFF;
            width: 75%;
            margin-left: auto;
            margin-bottom: 10vh;

           .top-bar {
                width: 75%;
                height: 3px;
                margin-left: auto;
                background-color: #FFF;
           }

           .title {
                font-size: 3.5em;
                text-align: right;
                font-weight: bold;
                line-height: 1em;                
                margin: 0.2em 0;
           }

           .text {
                font-size: 1.1em;
                text-align: right;
                line-height: 1em;                
                width: 85%;
                margin: 0;
                margin-left: auto;
           }
        } 
        
        #text-2 {
            position: relative;
            top: 100%;
            opacity: 0;

            color: #FFF;
            width: 60%;
            margin-right: auto;

           .top-bar {
                width: 70%;
                height: 3px;
                margin-right: auto;
                background-color: #FFF;
           }

           .title {
                font-size: 8em;
                text-align: left;
                line-height: 0.6em;                
                margin: 0;
                font-weight: bold;
                letter-spacing: -5px;

                sup {
                    font-size: 0.5em;
                    letter-spacing: 0;
                }
           }

           .text {
                font-size: 1.1em;
                text-align: left;
                line-height: 1em;                
                margin: 0.5em 0;
           }
        }         

    }
   
}

@media screen and (max-width: 83em) {
     #section-1 {
          .text-container {
               width: 40%;
     
               #text-1 {
                    width: 80%;
     
                    .title {
                         font-size: 3em;
                    }
          
                    .text {
                         font-size: 1em;
                    }
               
               } 

               #text-2 {
                    width: 70%;
     
                    .title {
                         font-size: 6em;
                    }
          
                    .text {
                         font-size: 1em;
                    }
               
               }                
          }     
     }
}

@media screen and (max-width: 58em) {
     #section-1 {
          .text-container {
               width: 90%;
               margin-right: 5%;

               #text-1 {
                    width: 50%;
               } 

               #text-2 {
                    width: 60%;
                    
                    .top-bar {
                         width: 55%;
                    }

               }                
          }     
     }
}

//phone
@media screen and (max-width: 28em) {
     #section-1 {
          .text-container {

               #text-1 {
                    width: 90%;
     
                    .title {
                         font-size: 2.5em;
                    }
          
                    .text {
                         font-size: 1em;
                         width: 100%;
                    }
               
               } 

               #text-2 {
                    width: 70%;
     
                    .title {
                         font-size: 6em;
                    }
          
                    .text {
                         font-size: 1em;
                    }
               
               }                
          }     
     }
}