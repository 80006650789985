#section-4 {
    height: 100vh;
    width: 100vw;   
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    background-color: #1e1e1e;
    
    // #intro-video {
    //     height: 56.25vw;
    //     width: 100vw;        
    // }

    #hero-video {
        height: 100vh;
        width: 178vh;        
        min-width: 100vw;
        object-fit: cover;
        position: absolute;
        top: 0;
    }    

}