.compatibility-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 0;
    z-index: 15;

    .compatibility-mask {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color:rgba(0, 0, 0, 0.6);
        opacity: 0;
    }
}

.compatibility-container {
    height: 100vh;
    width: 40vw; 
    max-width: 550px;  
    padding: 2em;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: fixed;
    top: 0; right: 0;
    transform: translateX(100%);
    z-index: 100;
    background-color: #000;
    color: #FFF;

    .back-button {
        position: absolute;
        top: 1em;
        left: 1em;
        height: 3em;
        width: auto;
        cursor: pointer;
    }

    h1 {
        font-size: 3em;
        line-height: 0.9em;
        margin: 0;
        margin-top: 2em;
        align-self: start;
    }

    p {
        margin: 2em 0;
        width: 80%;
        margin-right: auto;        
        font-size: 1em;        
    }

    .form-input {
        display: flex;
        flex-direction: column;
    }

    form {
        margin-bottom: auto;
        width: 100%;

        input {
            background-color: #FFF;
            font-size: 1.2em;
            padding: 1em;
            padding-top: 1.2em;
            width: 50%;
            color: #000;
            font-family: 'Inter', sans-serif; 
            border: none; 
            margin: 0;
            margin-left: auto;
            line-height: 1em;
            outline: none;            
            border-radius: 0.5em;
            text-align: center;
            box-sizing: border-box;
            width: 100%;
        }

        .error-message, .confirm-message {
            color: #DD0000;
       }

       .confirm-message {
        font-size: 2em;
        line-height: 0.9em;
        font-weight: bold;  
        color: #FFF;      
       }

       .confirm-title {
            color: #FFF;
       }        

        button {

            border: none;
            font-size: 1.3em;
            color: #FFF;
            background-color: #000;       
            border: 1px solid #FFF;              
            padding: 0.4em 1.2em;
            font-family: 'Inter', sans-serif;
            height: fit-content;    
            width: 80%;
            align-self: center;
            border-radius: 0.2em;
            margin: 0;
            margin-top: 1em;                
            transition-duration: 500ms;
            
            &:hover {
                color: #000;                
                background-color: #FFF;     
            }
                       
        }
    }
}

//tablet (portrait)
@media screen and (max-width: 55em) {
    .compatibility-container {
        width: 100vw;
        max-width: initial;
        align-items: center;
    }
}

//phone
@media screen and (max-width: 28em) {
    .compatibility-container {
        width: 100vw;
    }
}